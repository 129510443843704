<app-info-modal i18n buttonText="OK" [show]="show" (closeModal)="handleCloseModal()" *ngIf="modalContent$ | async as modalContent" data-testid="free-bets-list-modal">
  <div class="header" header>{{ modalContent.modalTitle }}</div>
  <div class="body" body>
    <app-card>
      <div class="body-card" body>
        <div class="body-title" data-testid="free-bets-list-modal-title">{{ modalContent.modalSubtitle }}</div>
        <div class="body-description" data-testid="free-bets-list-modal-desc">{{ modalContent.modalBodyText }}</div>
        <div class="voucher-list" data-testid="free-bets-voucher-list">
          <freebets-voucher
            *ngFor="let voucher of freebetVouchers$ | async; trackBy: voucherTrackBy"
            [code]="voucher.code"
            [name]="voucher.name"
            [expirationDate]="voucher.endDate"
            [amount]="voucher.reward.remainingValue"
            [showToggle]="true"
            [showExpirationDate]="true"
            [productType]='productType'
            (clickToggle)="handleClickToggle($event)"
            [redemptionCriteria]="voucher.reward.redemptionCriteria"
          ></freebets-voucher>
        </div>
        <div class="terms-and-conditions-wrapper" data-testid="free-bets-list-terms-condition">
          <a class="terms-and-conditions" [href]="modalContent.modalTCUrl">{{ modalContent.modalTCLabel }}</a>
        </div>
      </div>
    </app-card>
  </div>
</app-info-modal>
